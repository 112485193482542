import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',

  advertisement: {
    list: '/api/advertisement/list/',
    home: '/api/home/advertisement/',
    crime: '/api/crime/advertisement/',
    bangladesh: '/api/bangladesh/advertisement/',
    politics: '/api/politics/advertisement/',
    international: '/api/international/advertisement/',
    economy: '/api/economy/advertisement/',
    sports: '/api/sports/advertisement/',
    jobs: '/api/jobs/advertisement/',
    opinion: '/api/opinion/advertisement/',
    lifeStyle: '/api/life-style/advertisement/',
    expatriate: '/api/expatriate/advertisement/',
    entertainment: '/api/entertainment/advertisement/',
    business: '/api/business/advertisement/',
    photo: '/api/photo/advertisement/',
  },

  latestNews: {
    list: '/api/latest-post/list/',
    details: '/api/latest-post/list/',
    latest: '/api/latest-post/list/',
    search: '/api/latest-post/list/',
  },
  crimeNews: {
    list: '/api/crime/list/',
    details: '/api/crime/list/',
    latest: '/api/crime/list/',
    search: '/api/crime/list/',
  },
  politicsNews: {
    list: '/api/politics/list/',
    details: '/api/politics/list/',
    latest: '/api/politics/list/',
    search: '/api/politics/list/',
  },
  bangladeshNews: {
    list: '/api/bangladesh/list/',
    details: '/api/bangladesh/list/',
    latest: '/api/bangladesh/list/',
    search: '/api/bangladesh/list/',
  },
  internationalNews: {
    list: '/api/international/list/',
    details: '/api/international/list/',
    latest: '/api/international/list/',
    search: '/api/international/list/',
  },
  economyNews: {
    list: '/api/economy/list/',
    details: '/api/economy/list/',
    latest: '/api/economy/list/',
    search: '/api/economy/list/',
  },
  sportsNews: {
    list: '/api/sports/list/',
    details: '/api/sports/list/',
    latest: '/api/sports/list/',
    search: '/api/sports/list/',
  },
  jobsNews: {
    list: '/api/jobs/list/',
    details: '/api/jobs/list/',
    latest: '/api/jobs/list/',
    search: '/api/jobs/list/',
  },
  opinionNews: {
    list: '/api/opinion/list/',
    details: '/api/opinion/list/',
    latest: '/api/opinion/list/',
    search: '/api/opinion/list/',
  },
  lifeStyleNews: {
    list: '/api/life-style/list/',
    details: '/api/life-style/list/',
    latest: '/api/life-style/list/',
    search: '/api/life-style/list/',
  },
  videosNews: {
    list: '/api/video/list/',
    details: '/api/video/list/',
    latest: '/api/video/list/',
    search: '/api/video/list/',
  },
  expatriateNews: {
    list: '/api/expatriate/list/',
    details: '/api/expatriate/list/',
    latest: '/api/expatriate/list/',
    search: '/api/expatriate/list/',
  },
  entertainmentNews: {
    list: '/api/entertainment/list/',
    details: '/api/entertainment/list/',
    latest: '/api/entertainment/list/',
    search: '/api/entertainment/list/',
  },
  businessNews: {
    list: '/api/business/list/',
    details: '/api/business/list/',
    latest: '/api/business/list/',
    search: '/api/business/list/',
  },
  photoNews: {
    list: '/api/photo/list/',
    details: '/api/photo/list/',
    latest: '/api/photo/list/',
    search: '/api/photo/list/',
  },
  comments: {
    post: '/api/latest-post/comments/',
    like:'/api/like/post/',
    likeV: '/api/like/video/',
  },

  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
