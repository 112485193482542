// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'অধ্যায়',
    children: [
      { name: 'সর্বশেষ', href: paths.latestNews.root },
      { name: 'অপরাধ', href: paths.crimeNews.root },
      { name: 'বাংলাদেশ', href: paths.bangladesh.root },
      { name: 'যোগাযোগ করুন', href: paths.contact },
    ],
  },
  {
    headline: 'অধ্যায়',
    children: [
      { name: 'রাজনীতি', href: paths.politics.root },
      { name: 'আন্তর্জাতিক', href: paths.internationalNews.root },
      { name: 'সাম্প্রতিক', href: paths.recentNews.root },
      { name: 'আমাদের সম্পর্কে', href: paths.about },
    ],
  },
  {
    headline: 'অধ্যায়',
    children: [
      { name: 'অর্থনীতি', href: paths.theEconomy.root },
      { name: 'ক্রীড়া সংবাদ', href: paths.sportsNews.root },
      { name: 'বিনোদন', href: paths.entertainment.root },
      { name: 'প্রবাস জীবন', href: paths.expatriateLife.root }
    ],

  },
  {
    headline: 'অধ্যায়',
    children: [
      { name: 'চাকুরির সংবাদ', href: paths.jobNews.root },
      { name: 'মতামত', href: paths.opinion.root },
      { name: 'লাইফ স্টাইল', href: paths.lifeStyle.root },
      { name: 'ভিডিও কর্নার', href: paths.videos.root },
    ],
  },
  {
    headline: 'Editorialnews24',
    children: [
      { name: 'আমাদের সম্পর্কে', href: paths.aboutus },
      { name: 'যোগাযোগ করুন', href: paths.contactus },
      { name: 'গোপনীয়তা', href: paths.privacy },
      { name: 'বিধি - নিষেধ এবং শর্তাবলী', href: paths.terms },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const settings = useSettingsContext();

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
        maxWidth={settings.themeStretch ? false : 'lg'}
      >
        <Logo sx={{ mb: 3 }} />

        {/* <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          <Box
            component="img"
            src='/logo/full logo.png'
            sx={{ height: 60, cursor: 'pointer', m: 2
            }}
            />
        </Link> */}
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={4}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              সংবাদ এবং আন্তর্জাতিক সংবাদের সবচেয়ে নির্ভরযোগ্য এবং ব্যাপক উত্সগুলির মধ্যে একটি। এটি গভীরভাবে বিশ্লেষণ, ব্রেকিং নিউজ এবং অপরাধ, রাজনীতি, ব্যবসা,
              প্রযুক্তি, বিজ্ঞান, স্বাস্থ্য এবং বিনোদন সহ বিস্তৃত বিষয় কভার করে মাল্টিমিডিয়া সামগ্রী প্রদান করে।
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  onClick={() => window.open(social.path)}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={8}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 5, textAlign:'center' }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return  mainFooter;
}
