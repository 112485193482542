// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  logo: '/logo',
  aboutus: '/aboutus',
  contactus: '/contactus',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  privacy: '/privacy',
  terms: '/terms-condition',
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  latestNews: {
    root: `/latest-news`,
    details: (id) => `/latest-news/${id}`,
    demo: {
      details: `/latest-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  crimeNews: {
    root: `/crime-news`,
    details: (title) => `/crime-news/${(title)}`,
    demo: {
      details: `/crime-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  bangladesh: {
    root: `/bangladesh`,
    details: (title) => `/bangladesh/${paramCase(title)}`,
    demo: {
      details: `/bangladesh/${paramCase(MOCK_TITLE)}`,
    },
  },
  politics: {
    root: `/politics`,
    details: (title) => `/politics/${paramCase(title)}`,
    demo: {
      details: `/politics/${paramCase(MOCK_TITLE)}`,
    },
  },
  internationalNews: {
    root: `/international-news`,
    details: (title) => `/international-news/${paramCase(title)}`,
    demo: {
      details: `/international-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  recentNews: {
    root: `/recent-news`,
    details: (title) => `/recent-news/${paramCase(title)}`,
    demo: {
      details: `/recent-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  theEconomy: {
    root: `/the-economy`,
    details: (title) => `/the-economy/${(title)}`,
    demo: {
      details: `/the-economy/${paramCase(MOCK_TITLE)}`,
    },
  },
  sportsNews: {
    root: `/sports-news`,
    details: (title) => `/sports-news/${paramCase(title)}`,
    demo: {
      details: `/sports-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  jobNews: {
    root: `/job-news`,
    details: (title) => `/job-news/${paramCase(title)}`,
    demo: {
      details: `/job-news/${paramCase(MOCK_TITLE)}`,
    },
  },
  entertainment: {
    root: `/entertainment`,
    details: (title) => `/entertainment/${paramCase(title)}`,
    demo: {
      details: `/entertainment/${paramCase(MOCK_TITLE)}`,
    },
  },

  opinion: {
    root: `/opinion`,
    details: (title) => `/opinion/${paramCase(title)}`,
    demo: {
      details: `/opinion/${paramCase(MOCK_TITLE)}`,
    },
  },
  videos: {
    root: `/videos`,
    details: (title) => `/videos/${(title)}`,
    demo: {
      details: `/videos/${paramCase(MOCK_TITLE)}`,
    },
  },
  expatriateLife: {
    root: `/expatriate-life`,
    details: (title) => `/expatriate-life/${paramCase(title)}`,
    demo: {
      details: `/expatriate-life/${paramCase(MOCK_TITLE)}`,
    },
  },
  lifeStyle: {
    root: `/life-style`,
    details: (title) => `/life-style/${paramCase(title)}`,
    demo: {
      details: `/life-style/${paramCase(MOCK_TITLE)}`,
    },
  },
  businessNews: {
    root: `/business-news`,
    details: (id) => `/business-news/${id}`,
    demo: {
      details: `/business-news/${paramCase(MOCK_TITLE)}`,
    },
  },

  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },

  },
};
