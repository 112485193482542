// routes
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'সর্বশেষ',
    icon: <Iconify icon="clarity:new-solid" />,
    path: '/latest-news',
  },
  {
    title: 'অপরাধ',
    icon: <Iconify icon="game-icons:crime-scene-tape" />,
    path: '/crime-news',
  },
  {
    title: 'বাংলাদেশ',
    icon: <Iconify icon="emojione-v1:flag-for-bangladesh" />,
    path: '/bangladesh',
  },
  {
    title: 'রাজনীতি',
    icon: <Iconify icon="streamline:politics-speech" />,
    path: '/politics',
  },
  {
    title: 'আন্তর্জাতিক',
    icon: <Iconify icon="icon-park-outline:international" />,
    path: '/international-news',
  },
  // {
  //   title: 'সাম্প্রতিক',
  //   icon: <Iconify icon="fluent-mdl2:recent" />,
  //   path: '/recent-news',
  // },
  {
    title: 'অর্থনীতি',
    icon: <Iconify icon="solar:traffic-economy-bold-duotone" />,
    path: '/the-economy',
  },
  {
    title: 'ক্রীড়া সংবাদ',
    icon: <Iconify icon="ic:outline-sports-handball" />,
    path: '/sports-news',
  },
  {
    title: 'ব্যবসা',
    icon: <Iconify icon="icon-park-outline:entertainment" />,
    path: '/business-news',
  },
  {
    title: 'চাকুরির সংবাদ',
    icon: <Iconify icon="hugeicons:job-link" />,
    path: '/job-news',
  },
  {
    title: 'মতামত',
    icon: <Iconify icon="majesticons:comment-line" />,
    path: '/opinion',
  },
  // {
  //   title: 'প্রবাস জীবন',
  //   icon: <Iconify icon="mdi:worker-outline" />,
  //   path: '/expatriate-life',
  // },
  {
    title: 'লাইফ স্টাইল',
    icon: <Iconify icon="material-symbols:shelf-position" />,
    path: '/life-style',
  },
  // {
  //   title: 'ভিডিও কর্নার',
  //   icon: <Iconify icon="ph:video-light" />,
  //   path: '/videos',
  // },

  // {
  //   title: 'Components',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.components,
  // },
  {
    title: 'পৃষ্ঠাগুলি',
    path: '/pages',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    children: [
      {
        subheader: 'অন্যান্য',
        items: [
          { title: 'ভিডিও কর্নার', path: paths.videos.root },
          { title: 'প্রবাস জীবন', path: paths.expatriateLife.root },
          { title: 'বিনোদন', path: paths.entertainment.root },
          // { title: 'সাম্প্রতিক', path: paths.recentNews.root },
        ],
      },
      // {
      //   subheader: 'Concepts',
      //   items: [
      //     { title: 'Shop', path: paths.product.root },
      //     { title: 'Product', path: paths.product.demo.details },
      //     { title: 'Checkout', path: paths.product.checkout },
      //   ],
      // },
      // {
      //   subheader: 'Auth Demo',
      //   items: [
      //     { title: 'Login', path: paths.authDemo.classic.login },
      //     { title: 'Register', path: paths.authDemo.classic.register },
      //     {
      //       title: 'Forgot password',
      //       path: paths.authDemo.classic.forgotPassword,
      //     },
      //     { title: 'New password', path: paths.authDemo.classic.newPassword },
      //     { title: 'Verify', path: paths.authDemo.classic.verify },
      //     { title: 'Login (modern)', path: paths.authDemo.modern.login },
      //     { title: 'Register (modern)', path: paths.authDemo.modern.register },
      //     {
      //       title: 'Forgot password (modern)',
      //       path: paths.authDemo.modern.forgotPassword,
      //     },
      //     {
      //       title: 'New password (modern)',
      //       path: paths.authDemo.modern.newPassword,
      //     },
      //     { title: 'Verify (modern)', path: paths.authDemo.modern.verify },
      //   ],
      // },
      // {
      //   subheader: 'Error',
      //   items: [
      //     { title: 'Page 403', path: paths.page403 },
      //     { title: 'Page 404', path: paths.page404 },
      //     { title: 'Page 500', path: paths.page500 },
      //   ],
      // },
      // {
      //   subheader: 'Dashboard',
      //   items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
      // },
    ],
  },
  // {
  //   title: 'Docs',
  //   icon: <Iconify icon="solar:notebook-bold-duotone" />,
  //   path: paths.docs,
  // },
];
